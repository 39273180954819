import React from "react";
import Terminal from "./views/Terminal";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

function App() {
  return (
    <>
      <Terminal />
    </>
  );
}

export default App;
