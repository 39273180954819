import React from "react";
import "./../css/whoami.scss";

function Whoami() {
  return (
    <div className="px-2 py-2">
      <div className="info-container px-3">
        {/* Nombre y Rol */}
        <div className="row">
          <div className="col-12 p-2">
            <div className="d-flex flex-column">
              <h3 className="fw-bold">Jacob G. Acosta</h3>
              <span className="role">Software Architect</span>
            </div>
          </div>
        </div>
        {/* Acerca de */}
        <div className="row">
          <div className="col-12 p-2">
            <span className="fw-bold about">About 😎</span>
            <p className="mt-3">
              I'm a deeply passionate about coding. With over 15 years of
              experience in software development, I have honed a comprehensive
              skill set through my involvement in numerous high-profile
              projects. My journey in the tech industry has been marked by
              collaborations with distinguished companies and exceptional teams.
            </p>
          </div>
        </div>
        {/* Habilidades */}
        <div className="row">
          <div className="col-12 p-2">
            <div>
              <span className="fw-bold skills">Skills 🤓</span>
              <p className="skills-description mt-3">
                I have learned and experienced a variety of technologies with
                which I have created amazing things.
              </p>
            </div>
            <div className="glowing-gradient-border d-flex flex-column">
              <span className="fw-bold skills-title">My recently used skills 🧠</span>
              <ul className="skills-list list-unstyled mt-3 d-flex flex-column align-items-start">
                <h6>Back-end technologies:</h6>
                <li>* <span className="fw-bold">AWS</span>(IAM, Api Gataway, Lambda functions, Route53, AWS ACM, DynamoDB, Cognito, Amplify, S3, CloudFront EC2.)</li>
                <li>* <span className="fw-bold">OpenAI SDK</span> & <span className="fw-bold">Node Js</span></li>
                <li>* <span className="fw-bold">Java</span> (Spring Boot, Spring Oauth2.0) &<span className="fw-bold">Kotlin</span> (Jersey, Gradle, Maven)</li>
              </ul>
              <ul className="skills-list list-unstyled mt-3 d-flex flex-column align-items-start">
                <h6>Front-end technologies:</h6>
                <li>* <span className="fw-bold">Vue</span>(V2, V3)</li>
                <li>* <span className="fw-bold">React</span> (18.*)</li>
              </ul>
              <div className="mt-2 d-flex justify-content-around">
                <img src="/aws.svg" width={55} height={55} alt="" />
                <img src="/java.svg" width={40} height={40} alt="" />
                <img src="/kotlin.png" width={25} height={25} alt="" />
                <img src="/react.svg" width={30} height={30} alt="" />
                <img src="/vue.svg" width={30} height={30} alt="" />
                <img src="/nodejs.svg" width={30} height={30} alt="" />
                <img src="/openai.png" width={30} height={30} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* Tecnologías Front-end y Back-end */}
        <div className="row">
          <div className="col-md-4 col-sm-12 s-list">
            <div className="title">
              <span>Front-end technologies</span>
            </div>
            <ul className="p-2 list-unstyled">
              <li>JS</li>
              <li>Typescript</li>
              <li>React</li>
              <li>Vue</li>
              <li>Angular</li>
              <li>Vite</li>
              <li>Npm</li>
              <li>Babel</li>
              <li>Webpack</li>
            </ul>
          </div>
          <div className="col-md-8 col-sm-12 s-list">
            <div className="title">
              <span>Back-end technologies</span>
            </div>
            <ul className="p-2 list-unstyled">
              <li>
                Java (Spring, Spark, Jersey, Apache CXF, Maven, Gradle, Junit,
                Mockito, PowerMock, Hibernate, MyBatis, Log4j, JSF, etc).
              </li>
              <li>Kotlin (Ktor)</li>
              <li>Python (Django)</li>
              <li>PHP (Laravel)</li>
              <li>JS (NodeJs)</li>
              <li>Go</li>
            </ul>
          </div>
        </div>
        {/* CI, Sistemas operativos y Mobile */}
        <div className="row">
          <div className="col-md-4 col-sm-12 s-list">
            <div className="title">
              <span>CI</span>
            </div>
            <ul className="p-2 list-unstyled">
              <li>Jenkins</li>
              <li>Travis</li>
              <li>StyleCI</li>
              <li>Github actions</li>
            </ul>
          </div>
          <div className="col-md-4 col-sm-12 s-list">
            <div className="title">
              <span>Operating systems</span>
            </div>
            <ul className="p-2 list-unstyled">
              <li>Unix based</li>
              <li>Windows</li>
              <li>MacOs</li>
            </ul>
          </div>
          <div className="col-md-4 col-sm-12 s-list">
            <div className="title">
              <span>Mobile</span>
            </div>
            <ul className="p-2 list-unstyled">
              <li>Andrtoid JetPack (Java & kotlin)</li>
              <li>Swift (SwiftUI)</li>
            </ul>
          </div>
        </div>
        {/* Bases de datos, Servicios en la nube y Servidores */}
        <div className="row">
          <div className="col-md-4 col-sm-12 s-list">
            <div className="title">
              <span>Databases</span>
            </div>
            <ul className="p-2 list-unstyled">
              <li>Oracle</li>
              <li>PostgreSQL</li>
              <li>MongoDB</li>
              <li>DynamoDB</li>
              <li>MySQL</li>
              <li>SQLite</li>
              <li>DB2</li>
              <li>Sybase</li>
            </ul>
          </div>
          <div className="col-md-4 col-sm-12 s-list">
            <div className="title">
              <span>Cloud services</span>
            </div>
            <ul className="p-2 list-unstyled">
              <li>AWS</li>
              <li>Azure</li>
              <li>Openshift</li>
            </ul>
          </div>
          <div className="col-md-4 col-sm-12 s-list">
            <div className="title">
              <span>Server</span>
            </div>
            <ul className="p-2 list-unstyled">
              <li>Nginx</li>
              <li>JBoss</li>
              <li>Websphere</li>
              <li>Weblogic</li>
              <li>Tomcat</li>
              <li>GlassFish</li>
              <li>Apache</li>
            </ul>
          </div>
        </div>
        {/* Otros */}
        <div className="row">
          <div className="col-lg-4 col-12 s-list">
            <div className="title">
              <span>More</span>
            </div>
            <ul className="p-2 list-unstyled">
              <li>Docker</li>
              <li>Bugsnag</li>
              <li>Dependabot</li>
              <li>Blackduck</li>
              <li>MemCache</li>
              <li>Twilio</li>
              <li>Kafka</li>
            </ul>
          </div>
          <div className="col-lg-8 col-0 s-list">
            <div className="title">
              <span>&nbsp;</span>
            </div>
          </div>
        </div>
      </div>
      <div className="more-info mt-4">
        <p className="mb-0">
          For more information, use the <span className="fw-bold">help</span>{" "}
          command.
        </p>
      </div>
    </div>
  );
}

export default Whoami;
