import React from "react";
import DevContact from "./DevContact";
import DevEmail from "./DevEmail";
import DevUndefined from "./DevUndefined";

interface DevProps {
  option: string | null;
  blockingAction: () => void;
}

function Dev({ option, blockingAction }: DevProps) {
  switch (option) {
    case "contact":
      return <DevContact />;
    default:
      return <DevUndefined command={option} />;
  }
}

export default Dev;
