import React from "react";
import "./../css/devcontact.scss";

function DevContact() {
  return (
    <div className="py-2 dev-contact">
      <div className="framework p-1">
        <h3 className="text-center">Jacob G. Acosta</h3>
        <p className="mt-4">
          <span className="fw-bold">Github:</span>{" "}
          https://github.com/jacobgacosta
        </p>
        <p>
          <span className="fw-bold">LinkedIn profile:</span>{" "}
          linkedin.com/in/jgacosta
        </p>
        <p>
          <span className="fw-bold">Current location:</span> México
        </p>
        <p>
          <span className="fw-bold">Email:</span> jacob.gacosta@gmail.com
        </p>
      </div>
    </div>
  );
}

export default DevContact;
