import React, { useState, useEffect, useRef } from "react";
import Whoami from "./Whoami";
import Help from "./Help";
import Dev from "./Dev";
import UndefinedCommand from "./UndefinedCommand";
import { showMobileKeyboard } from "../js/mobile.Keyboard";

const Terminal: React.FC = () => {
  const [inputText, setInputText] = useState<string>("");
  const inputRef = useRef<HTMLSpanElement>(null);
  const [writting, setWritting] = useState<boolean>(true);
  const [elements, setElements] = useState<JSX.Element[]>([]);
  const [simulateEnter, setSimulateEnter] = useState<boolean>(false);
  const [blockedPrompt, setblockedPrompt] = useState<boolean>(false);
  const [undefinedCommand, setUndefinedCommand] = useState<boolean>(false);

  const moveCursorToEnd = () => {
    if (inputRef.current) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(inputRef.current);
      range.collapse(false);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  };

  const goToBottom = () => {
    setTimeout(() => {
      if (/iPhone/i.test(navigator.userAgent)) {
        const lastDiv = document.getElementById("comm");
        if (lastDiv) {
          const { top, height } = lastDiv.getBoundingClientRect();
          const scrollTop =
            window.scrollY || document.documentElement.scrollTop;
          const scrollTo = scrollTop + top + height + 30; // Ajusta el valor según lo necesites
          window.scrollTo({ top: scrollTo, behavior: "smooth" });
        }
      } else {
        const lastDiv = document.getElementById("comm");
        lastDiv?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }, 100);
    moveCursorToEnd();
  };

  useEffect(() => {
    const word = "whoami";
    let i = 0;
    let currentText = "";

    const typingInterval = setInterval(() => {
      if (i < word.length) {
        currentText += word[i];
        setInputText(currentText);
        i++;
      } else {
        setWritting(false);
        clearInterval(typingInterval);
        setSimulateEnter(true);
      }
    }, 150);

    return () => clearInterval(typingInterval);
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.textContent = inputText;
      //inputRef.current.focus();
      moveCursorToEnd();
    }
  }, [inputText]);

  useEffect(() => {
    /*window.addEventListener("click", handleWindowFocus);

    return () => {
      window.removeEventListener("click", handleWindowFocus);
    };*/
  }, []);

  useEffect(() => {
    if (simulateEnter) {
      handleKeyDown({ key: "Enter" } as React.KeyboardEvent<HTMLSpanElement>);
      setSimulateEnter(false);
    }
  }, [simulateEnter]);

  const handleInput = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (writting) {
      return;
    }

    const currentText = event.currentTarget.textContent || "";
    setInputText(currentText);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (writting) {
      return;
    }

    if (event.key === "Enter") {
      const command = inputRef.current?.textContent || "";

      if (command) {
        const newElements = [...elements, processedCommand(true)];
        setElements(newElements);
        setInputText("");
      }

      if (command == "cls") {
        setElements([]);
        setInputText("");
        event.preventDefault();
        setTimeout(() => {
          inputRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "start",
          });
        }, 0);

        return;
      }

      if (event.preventDefault) {
        event.preventDefault();
        setTimeout(() => {
          inputRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }, 0);
      }
    }
  };

  const processedCommand = (isProcessed: Boolean) => (
    <>
      <div className="terminal px-2 d-flex align-items-center pt-2">
        <span className="prompt">$</span>
        <span
          className="input"
          spellCheck={false}
          style={{ outline: "none", textDecoration: "none" }}
        ></span>
        <span className="text">{inputText}</span>
      </div>
      {isProcessed && <div className="response">{renderComponent()}</div>}
    </>
  );

  const prompt = () => (
    <>
      <div
        onClick={goToBottom}
        className="terminal px-2 d-flex align-items-center pt-2 pb-4"
      >
        <span className="prompt">$</span>
        <span
          id="comm"
          className="input text"
          contentEditable={true}
          ref={inputRef}
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          spellCheck={false}
          style={{ outline: "none", textDecoration: "none" }}
        ></span>
        <span className="cursor"></span>
      </div>
    </>
  );

  const renderComponent = () => {
    if (/^dev(\s+--\S+)?$/i.test(inputText)) {
      const match = inputText.match(/--(.+)/);
      const option = match ? match[1] : null;
      goToBottom();
      return <Dev option={option} blockingAction={isBlocked} />;
    } else if (inputText === "whoami") {
      return <Whoami />;
    } else if (inputText === "help") {
      goToBottom();
      return <Help />;
    } else {
      setUndefinedCommand(true);
      return <UndefinedCommand command={inputText} />;
    }
  };

  const isBlocked = () => {
    setblockedPrompt(true);
  };

  return (
    <>
      {elements.map((element, index) => (
        <React.Fragment key={index}>{element}</React.Fragment>
      ))}
      {!blockedPrompt ? prompt() : null}
    </>
  );
};

export default Terminal;
