import React from "react";
import "./../css/undefined.scss";

interface UndefinedCommandProps {
  command: string | null;
}

function UndefinedCommand(prop: UndefinedCommandProps) {
  const { command } = prop;

  return (
    <div className="px-4 py-2 undefined-command">
      <p className="mb-0">[{command}] is not recognized as a command name.</p>
    </div>
  );
}

export default UndefinedCommand;
