import React from "react";
import "./../css/help.scss";

function Help() {
  return (
    <div className="px-2 py-2 available-commands">
      <div className="ms-0 mt-4">
        <table className="w-100">
          <tr>
            <th>Command</th>
            <th>Description</th>
          </tr>
          <tr>
            <td className="command">cls</td>
            <td>Clear the screen</td>
          </tr>
          <tr>
            <td className="command">dev --contact</td>
            <td>Show dev info</td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Help;
